<template>
  <div class="container">
    <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-if="loading"
      size="24px"
      >加载中...</van-loading
    >
    <div v-if="loading == false" style="height: 100%">
      <div>
        <van-sticky>
          <van-nav-bar :title="title" left-arrow @click-left="$router.back()" />
        </van-sticky>
      </div>

      <van-form @submit="onSubmit" style="padding-bottom: 60px">
        <div>
          <van-field
            v-model="bbs.title"
            name="bbstitle"
            label="标题"
            placeholder="请输入标题"
            required
            :rules="[{ required: true, message: '请输入标题' }]"
          />
          <van-row>
            <van-col span="12">
              <van-field
                v-model="typefieldValue"
                is-link
                readonly
                placeholder="选择主题"
                @click="showType = true"
               
              />
            </van-col>
            <van-col span="12">
              <van-field
                name="bbsProduct"
                placeholder="关联产品"
                readonly
                v-model="productName"
                is-link
                @click="showProductHandle"
                v-if="bbs.systemType == 0 || bbs.systemType == 1"
              />
            </van-col>
          </van-row>

          <van-popup v-model="showType" round position="bottom">
            <van-cascader
              v-model="cascaderValue"
              title="选择主题"
              :options="columns"
              @close="showType = false"
              @change="typeonChange"
              @finish="typeonFinish"
            />
          </van-popup>
          <van-popup
            v-model="showProduct"
            closeable
            position="bottom"
            :style="{ height: '90%' }"
          >
            <van-nav-bar title="选择关联产品" />
            <van-row>
              <van-col span="8">
                <van-dropdown-menu>
                  <van-dropdown-item
                    v-model="product.page.filterData.productClass"
                    :options="product.pclassOption"
                    @change="productonSearch"
                  />
                </van-dropdown-menu>
              </van-col>
              <van-col span="14">
                <van-search
                  v-model="product.page.filterData.name"
                  @search="productonSearch"
                  placeholder="请输入搜索关键词"
                />
              </van-col>
            </van-row>

            <van-list
              v-model="product.upLoading"
              :finished="product.finished"
              @load="productonLoad"
              finished-text="没有更多了"
            >
              <div class="van-clearfix">
                <div
                  class="layout_wrapper"
                  style="
                    float: left;
                    width: 50%;
                    text-align: center;
                    background-color: #f8f8f8;
                    overflow: hidden;
                  "
                  @click="producttoDetail(item)"
                  v-for="item in product.articles"
                  :key="item.id.toString()"
                >
                  <div class="product-layout__item">
                    <van-image
                      lazy-load
                      class="w100"
                      fit="contain"
                      style="max-height: 200px"
                      :src="item.imageUrl"
                    />

                    <div style="height: 42px">
                      <h3 style="display: inline-block; height: 21px">
                        {{ item.name }}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </van-list>
          </van-popup>
        </div>
        <SelectDepartment
          v-if="bbs.systemType == 1 || bbs.systemType == 2"
          @ChangecheckedDepartment="ChangecheckedDepartment"
        />

        <QuillEditor
          ref="editor2"
          v-model="bbs.detailsHtml"
          @onInputHandler="onInput"
          contentTmpType="bbs"
        />
        <!--上传预览-->
        <div class="van-hairline--top-bottom" v-show="imagefileList.length > 0">
          <van-row>
            <van-col span="24">
              <van-uploader
                :multiple="true"
                v-model="imagefileList"
                :max-count="200"
                :after-read="afterReadImage"
              />
            </van-col>
          </van-row>
        </div>

        <div class="van-hairline--top-bottom" v-show="audioName.length > 0">
          <van-row>
            <van-col span="23" offset="1">
              <p>已上传音频: {{ audioName }}</p>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="16" offset="2">
              <div class="znaudio">
                <div class="name">
                  <!-- {{ audioName }} -->
                </div>
                <audio-player
                  ref="audioPlayer"
                  :showPrevButton="false"
                  :showNextButton="false"
                  :isLoop="false"
                  :showVolumeButton="false"
                  :progress-interval="100"
                  :audio-list="[bbs.audioUrl]"
                  theme-color="rgb(157,29,34)"
                />
              </div>
            </van-col>
            <van-col span="4" offset="1">
              <van-button
                size="small"
                round
                block
                type="info"
                plain
                @click="removeAudio"
                >删除</van-button
              >
            </van-col>
          </van-row>
        </div>
        <div class="van-hairline--top-bottom" v-show="videoName.length > 0">
          <van-row>
            <van-col span="23" offset="1">
              <p>已上传视频: {{ videoName }}</p>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="16" offset="2">
              <div class="znvideo">
                <video
                  style="max-width: 100%"
                  controls=""
                  :src="bbs.videoUrl"
                ></video>
              </div>
            </van-col>
            <van-col span="4" offset="1">
              <van-button
                size="small"
                round
                block
                plain
                type="info"
                @click="removeVideo"
                >删除</van-button
              >
            </van-col>
          </van-row>
        </div>

        <div
          style="
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 5px;
            background-color: #ededed;
          "
          class="addbottom"
        >
          <van-row>
            <van-col span="5" style="text-align: center">
              <van-uploader
                multiple
                :max-count="200"
                :after-read="afterReadImageOut"
              >
                <div>
                  <van-icon size="30" name="/image/image.png" />
                </div>
              </van-uploader>
            </van-col>
            <van-col span="5" style="text-align: center">
              <van-uploader accept="audio/*" :after-read="afterReadAudio">
                <div>
                  <van-icon size="30" name="/image/audio.png" />
                </div>
              </van-uploader>
            </van-col>
            <van-col span="5" style="text-align: center">
              <van-uploader
                upload-text=""
                accept="video/mp4"
                :after-read="afterReadVideo"
              >
                <div>
                  <van-icon size="30" name="/image/video.png" />

                  <!-- <p style="color: #ee0a24" v-show="videoName.length > 0">
                      已上传: {{ videoName }}
                    </p> -->
                </div>
              </van-uploader>
            </van-col>
            <van-col span="6" offset="2">
              <div style="padding-right: 10px">
                <van-button
                  size="small"
                  round
                  block
                  :disabled="onsubmiting"
                  :loading="onsubmiting"
                  loading-text="提交..."
                  type="info"
                  native-type="submit"
                  >提交</van-button
                >
              </div>
            </van-col>
          </van-row>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import AudioPlayer from "@liripeng/vue-audio-player";

import "@liripeng/vue-audio-player/lib/vue-audio-player.css";
import { Toast } from "vant";

import { uploadfile } from "@/api/file";
import {
  AddBbs,
  getBBSTypeList,
  getBBSTopicList,
  getBBSTopicS7List,
  getActivityType,
} from "@/api/bbs";
import {
  getZNProductList,
  getZNProductClassList,
  getProductName,
} from "@/api/product";
import {  getArticleTypeList } from "@/api/article";
import QuillEditor from "../components/quillEditor";
import SelectDepartment from "./components/selectDepartment";

export default {
  name: "addBBS",
  data() {
    return {
      onsubmiting: false,
      imagefileList: [],
      departmentId: 0,
      editoroninput: false,

      s7topicGroup: [],
      s7topicList: [],
      title: "中酿心声",
      audioName: "",
      videoName: "",
      typeValue: {},
      typefieldValue: "",
      showType: false,
      showPicker: false,
      cascaderValue: "",
      showProduct: false,
      productName: "",
      bbs: {
        systemType: 0,
        title: "",
        detailsHtml: "",
        audioUrl: "",
        videoUrl: "",
        typeId: 0,
        hrDepartmentId: 0,
        topicId: 0,
        productId: "00000000-0000-0000-0000-000000000000",
      },
      columns: [{ text: "宁波", value: 100 }],

      loading: true,

      product: {
        pclassOption: [{ text: "全部", value: 0 }],

        keyword: "",
        upLoading: false,
        finished: false,
        articles: [],
        page: {
          sorterType: 0,
          current: 1,
          pageSize: 10,
          filterData: {
            name: "",
            productClass: 0,
          },
        },
      },

      isShowSelectTemplate: false,
      workLogTemplates: [],
    };
  },
  components: {
    AudioPlayer,
    QuillEditor,
    SelectDepartment,
  },
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.query != from.query) {
        this.loading = true;
        this.initData();
      }
    },
  },
  mounted() {},
  computed: {},
  methods: {
    async initData() {
      let systemType = this.$route.query.systemType;
      if (systemType === undefined) {
        systemType = 0;
      }
      this.bbs.systemType = parseInt(systemType);

      let productId = this.$route.query.productId;
      if (productId === undefined) {
        productId = "00000000-0000-0000-0000-000000000000";
      }
      this.bbs.productId = productId;

      if (productId != "00000000-0000-0000-0000-000000000000") {
        let nameReuslt = await getProductName(productId);
        this.productName = nameReuslt.data.data;
      }

      await this.loadinitData();

      // pClassList.data.data.map((x) =>
      //   this.columns.push({ text: x.typeName, value: x.id })
      // );
      this.loading = false;
    },

    loadinitData: async function () {
      if (this.bbs.systemType == 0) {
        this.title = "中酿心声分享";
      }
      if (this.bbs.systemType == 1) {
        this.title = "经验萃取分享";
      }
      if (this.bbs.systemType == 2) {
        this.title = "学习成长分享";
      }
      let that = this;
      if (this.bbs.systemType == 0) {
        let pClassList = await getBBSTypeList();
        this.columns = [];

        for (let x of pClassList.data.data) {
          // this.columns.push({ text: x.typeName, value: x.id })

          let pTopicList = await getBBSTopicList({
            current: 1,
            pageSize: 20,
            filterData: { typeId: x.id },
          });
          var element = {};

          if (pTopicList.data.data.length > 0) {
            element.children = [];
            element.text = x.typeName;
            element.value = x.id;
            pTopicList.data.data.map((x) =>
              element.children.push({ text: x.topicName, value: x.id })
            );
            that.columns.push(element);
          } else {
            element.text = x.typeName;
            element.value = x.id;
            that.columns.push(element);
          }
        }
      }
      else if(this.bbs.systemType>0) {
        this.columns = [];
        let subTypeList = await getArticleTypeList({
        current: 1,
        pageSize: 100,
        filterData: {
          parentID: 4000+this.bbs.systemType,
        },
      });
      if (subTypeList.data.data.length > 0) {
        subTypeList.data.data.map((x) =>
          this.columns.push({ text: x.typeName, value: x.id.toString() })
        );
      }
      }

      if (this.bbs.systemType == 1) {
        let s7topicList = await getBBSTopicS7List({
          current: 1,
          pageSize: 20,
        });
        this.s7topicList = [];
        for (let x of s7topicList.data.data) {
          that.s7topicList.push(x);
        }
      }

      //是有活动
      let activityReuslt = await getActivityType();
      if (activityReuslt.data.success) {
        this.bbs.typeId = activityReuslt.data.data.id;
        this.cascaderValue = activityReuslt.data.data.id;
        this.typefieldValue = activityReuslt.data.data.typeName;
      }

      if (this.$route.query.typeId) {
        const cols = this.columns.filter(
          (x) => x.value == this.$route.query.typeId
        );
        if (cols.length > 0) {
          this.bbs.typeId = cols[0].value;
          this.bbs.topicId = 0;
          this.cascaderValue = cols[0].value;
          this.typefieldValue = cols[0].text;
        }
      }
    },
    onConfirm(value) {
      this.typeValue = value;
      this.showPicker = false;
    },
    
    removeAudio() {
      (this.audioName = ""), (this.bbs.audioUrl = "");
      //videoName: "",
    },
    removeVideo() {
      (this.videoName = ""), (this.bbs.videoUrl = "");
    },

    handleImageAdded: async function (
      file,
      Editor,
      cursorLocation,
      resetUploader
    ) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      let formData = new FormData();
      formData.append("file", file);

      let aresult = await uploadfile(formData);

      let url = aresult.data; // Get url from response
      Editor.insertEmbed(cursorLocation, "image", url);
      resetUploader();
    },
    afterReadImage: async function (files) {
      Toast("文件上传中");
      //多张图片循环上传
      var filelist = [];

      if (Object.prototype.toString.call(files) === "[object Array]") {
        files.reverse(); //根据选择顺序上传
        files.forEach((element) => {
          filelist.push(element.file);
        });
      } else {
        filelist.push(files.file);
      }
      for (let index = 0; index < filelist.length; index++) {
        let formData = new FormData();
        formData.append("file", filelist[index]);
        let aresult = await uploadfile(formData);
        let url = aresult.data; // Get url from response
        this.imagefileList[
          this.imagefileList.length - filelist.length + index
        ].url = url;
      }

      Toast("文件上传成功");
    },
    //点击下方的上传按钮
    afterReadImageOut: async function (files) {
      Toast("文件上传中");
      //多张图片循环上传
      var filelist = [];
      if (Object.prototype.toString.call(files) === "[object Array]") {
        files.reverse(); //根据选择顺序上传
        files.forEach((element) => {
          filelist.push(element.file);
        });
      } else {
        filelist.push(files.file);
      }

      for (let index = 0; index < filelist.length; index++) {
        let formData = new FormData();
        formData.append("file", filelist[index]);
        let aresult = await uploadfile(formData);

        let url = aresult.data; // Get url from response
        this.imagefileList.push({
          url: url,
          imageFit: "contain",
          previewSize: 80,
        });

        if (this.imagefileList.length == 20) {
          break;
        }
      }

      Toast("文件上传成功");
    },
    afterReadAudio: async function (files) {
      Toast("文件上传中");
      let formData = new FormData();
      formData.append("file", files.file);
      let aresult = await uploadfile(formData);

      let url = aresult.data; // Get url from response
      this.bbs.audioUrl = url;
      this.audioName = files.file.name;
      Toast("文件上传成功");
    },
    afterReadVideo: async function (files) {
      Toast("文件上传中");
      let formData = new FormData();
      formData.append("file", files.file);
      let aresult = await uploadfile(formData);
      let url = aresult.data; // Get url from response
      this.bbs.videoUrl = url;
      this.videoName = files.file.name;
      Toast("文件上传成功");
    },
    onInput: function (input) {
      console.log(input);
      console.log("oninput");
      this.editoroninput = input;
    },
    onSubmit: async function () {
      this.onsubmiting = true;
      //提交
      let baseinfo = "";
     
      this.bbs.detailsHtml = baseinfo + this.$refs.editor2.content;
      if (this.imagefileList.length > 0) {
        this.imagefileList.forEach((element) => {
          this.bbs.detailsHtml += "<img src='" + element.url + "'/>";
        });
      }
      if (this.bbs.detailsHtml.indexOf('img src="data:') != -1) {
        Toast("不允许粘贴图片,请手动上传");
        this.onsubmiting = false;
        return;
      }

      this.bbs.s7TopicId = this.s7topicGroup.toString();

      if (this.bbs.detailsHtml.length < 1) {
        Toast("请填写详细内容");
        this.onsubmiting = false;
        return;
      }
      this.bbs.hrDepartmentId = this.departmentId;

      const data = await AddBbs(this.bbs);
      if (data.data.success) {
        // if (this.bbs.systemType != 0) {
        //   Toast("提交成功,等待后台审核,审核通过后发放积分");
        // } else {
        if (
          data.data.scoreMessage !== undefined &&
          data.data.scoreMessage != ""
        ) {

          var singinIcon="/image/signin_point_add.png"
        if(data.data.scoreMessage.indexOf("能量值") >=0){
          singinIcon="/image/singinscoreblock1.png";
        }


          Toast({
            message: data.data.scoreMessage,
            icon: singinIcon,
          });
        } else {
          Toast("提交成功");
        }
        if (
          data.data.scoreMessage2 !== undefined &&
          data.data.scoreMessage2 != ""
        ) {
          setTimeout(() => {
            Toast({
              message: data.data.scoreMessage2,
              icon: "/image/qiang.png",
            });
          }, 2000);
        }
        //}

        this.audioName = "";
        this.videoName = "";
        this.bbs.detailsHtml = "";
        this.bbs.title = "";
        this.bbs.typeid = 0;
        this.bbs.topicId = 0;
        this.bbs.productId = "00000000-0000-0000-0000-000000000000";
        this.productName = "";
        this.cascaderValue = "";
        this.typefieldValue = "";

        //this.$router.push({ path: "/bbs" });
        this.$router.back();
      } else {
        Toast(data.data.errorMessage);
      }
      this.onsubmiting = false;
    },

    async typeonChange({ value, tabIndex }) {
      if (tabIndex == 0) {
        this.bbs.typeId = value;
        this.bbs.topicId = 0;
      }
      if (tabIndex == 1) {
        this.bbs.topicId = value;
      }

      //this.typefieldValue = value.map((option) => option.text).join('/');
    },
    typeonFinish({ selectedOptions }) {
      this.showType = false;

      this.typefieldValue = selectedOptions
        .map((option) => option.text)
        .join("/");
    },

    async showProductHandle() {
      let pClassList = await getZNProductClassList();
      this.product.pclassOption = [{ text: "全部", value: 0 }];

      pClassList.data.data.map((x) =>
        this.product.pclassOption.push({ text: x.name, value: x.id })
      );

      this.showProduct = true;
    },
    productonSearch() {
      this.product.finished = false;
      this.product.articles = [];
      this.product.page.current = 1;
      this.product.page.pageSize = 10;
      this.productonLoad();
    },
    changeSorterType(typeid) {
      if (this.product.page.sorterType != typeid) {
        this.product.page.sorterType = typeid;
        this.productonSearch();
      }
    },
    producttoDetail(item) {
      this.productName = item.name;
      this.bbs.productId = item.id;
      this.showProduct = false;
    },
    //

    toediteInfo() {
      this.$router.push({
        path: "/article/detail?para=307dce29-bccf-4a1d-a704-3ea039d517f3&type=104",
      });
    },
    tocourseadd() {
      this.$router.push({ path: "/course/add" });
    },
    toaddWorkLog() {
      if (this.workLogTemplates.length <= 1) {
        this.$router.push({
          path: "/worklog/add",
          query: {
            templateId:
              this.workLogTemplates.length == 1
                ? this.workLogTemplates[0].id
                : null,
          },
        });
      } else {
        this.isShowSelectTemplate = true;
      }
    },

    async productonLoad() {
      let aresult = await getZNProductList({ ...this.product.page });
      this.product.articles.push(...aresult.data.data);
      this.product.upLoading = false;
      if (
        this.product.page.current * this.product.page.pageSize <
        aresult.data.total
      ) {
        this.product.page.current++;
      } else {
        this.product.finished = true;
      }
    },

    onSelectTemplate(item) {
      this.isShowSelectTemplate = false;
      this.$router.push({
        path: "/worklog/add",
        query: { templateId: item.id },
      });
    },
    ChangecheckedDepartment(value) {
      this.departmentId = value;
    },
  },
};
</script>

<style>
/* .ql-fullscreen {
  width: 20px !important;
  background: url("/image/fullscreen.svg") no-repeat center !important;
} */
</style>
<style lang="less" scoped>
.loading {
  text-align: center;
}
.addbottom .van-cell {
  padding: unset;
  background-color: unset;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  /deep/.van-checkbox {
    margin-top: 5px;
  }
  /deep/ .btn-box {
    margin-top: 25px;
  }
}
// .ql-fullscreen {
//   background: url("/image/fullscreen.svg") no-repeat center !important;
// }

// .editor-fullscreen {
//   background: white;
//   margin: 0 !important;
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   z-index: 100000;
//   .ql-editor {
//     height: 100%;
//   }
//   .quillWrapper {
//     height: 80%;
//   }
//   .fullscreen-editor {
//     border-radius: 0;
//     border: none;
//   }
//   .ql-container {
//     height: calc(100vh - 3rem - 24px) !important;
//     margin: 0 auto;
//     overflow-y: auto;
//   }
// }

// .ql-fullscreen:before {
//   content: "Ω";
//   display: block;
//   font-family: anticon !important;
//   font-weight: bold;
// }
.product-layout__item {
  position: relative;
  display: block;
  box-sizing: border-box;
  min-height: 50px;
  overflow: hidden;
  color: #333;
  background-color: #fff;
  margin: 2px;
}
.w100 {
  /deep/ img {
    width: 100%;
    height: 200px;
    max-height: 200px;
  }
}
</style>