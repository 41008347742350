<template>
  <div role="button" tabindex="0" class="van-cell van-cell--clickable van-field">
    <div class="van-cell__title van-field__label"><span>{{ lableName }}</span></div>
    <div class="van-cell__value van-field__value">
      <div class="van-field__body">
        <van-radio-group v-model="checkedDepartment" direction="horizontal" @change="change">
          <van-radio :name="item.id" v-for="item in departmentItmeList" :key="item.id">{{ item.name }}</van-radio>
        </van-radio-group>
        <div v-if="departmentItmeList.length == 0" style="color: red;">未获取到您的部门关系，请联系管理员</div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetUserDepartmentPathItemList } from "@/api/bbs";

export default {
  name: "selectDepartment",
  props: {
    lableName: {
      type: String,
      default: "分享到"
    },
    setDeafult: {
      type: Boolean,
      default: true
    },
    checkedDepartment: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      hasdepartmentList: true,
      departmentItmeList: [],
    };
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      let reuslt = await GetUserDepartmentPathItemList();
      if (reuslt.data.success) {
        this.departmentItmeList = reuslt.data.data;
        if (this.setDeafult && this.checkedDepartment==0) {
          this.checkedDepartment = reuslt.data.data[0].id;
        }
      }
    },
    change(event) {
      this.$emit("ChangecheckedDepartment", event);
    },
  },
};
</script>

<style></style>              